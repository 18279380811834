import React, { useRef, useState } from "react";
import {
  AiOutlineFile,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlusCircle,
} from "react-icons/ai";

import { StyledFile } from "Tree/File/TreeFile.style";
import { useTreeContext } from "Tree/state/TreeContext";

import { FILE } from "Tree/state/constants";
const File = ({ name, id, details, node }) => {
  console.log("details is ", details, name);

  const { dispatch, isImparative, onNodeClick } = useTreeContext();
  const [isEditing, setEditing] = useState(false);
  const ext = useRef("");

  let splitted = name?.split(".");
  ext.current = splitted[splitted.length - 1];

  const toggleEditing = () => setEditing(!isEditing);
  const commitEditing = (name) => {
    dispatch({ type: FILE.EDIT, payload: { id, name } });
    setEditing(false);
  };
  const commitDelete = () => {
    dispatch({ type: FILE.DELETE, payload: { id } });
  };
  const handleNodeClick = React.useCallback(
    (e) => {
      e.stopPropagation();
      onNodeClick({ node });
    },
    [node]
  );
  const handleCancel = () => {
    setEditing(false);
  };

  return (
    <table className="table-str">
      <thead>
        <tr>
          <th className="">Connected Application</th>
          <th className="">Details</th>
          <th className="">Attachment</th>
        </tr>
      </thead>
      <tbody>
        {details.map((dm, i) => (
          <tr key={dm.id + i}>
            <td> {dm.connectedApp}</td>
            <td> {dm.details}</td>
            <td> {dm.attachment}</td>
          </tr>
        ))}
      </tbody>
    </table>
    // <StyledFile onClick={handleNodeClick} className="tree__file">
    //   {isEditing ? (
    //     <PlaceholderInput
    //       type="file"
    //       style={{ paddingLeft: 0 }}
    //       defaultValue={name}
    //       onSubmit={commitEditing}
    //       onCancel={handleCancel}
    //     />
    //   ) : (
    //     <ActionsWrapper>
    //       <StyledName>
    //         {FILE_ICONS[ext.current] ? (
    //           FILE_ICONS[ext.current]
    //         ) : (
    //           < AiOutlinePlusCircle/>
    //         )}
    //         &nbsp;&nbsp;{name}
    //       </StyledName>
    //       {isImparative && (
    //         <div className="actions">
    //           <AiOutlineEdit onClick={toggleEditing} />
    //           <AiOutlineDelete onClick={commitDelete} />
    //         </div>
    //       )}
    //     </ActionsWrapper>
    //   )}
    // </StyledFile>
  );
};

export { File };
