import React, { useState, useLayoutEffect } from "react";
import "./styles.css";

import Tree from "./Tree/Tree";

const structure = [
  {
    type: "folder",
    name: "Global",
    files: [
      {
        type: "folder",
        name: "Level 1 Coorporate",
        files: [
          {
            type: "folder",
            name: "Okta", 
            files: [
              {
                type: "file",
                name:"Okta",
                details: [
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 1,
                  },
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 2,
                  },
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 3,
                  },
                ],
              },
            ],
          },
          {
            type: "folder",
            name: "Active Directory", 
            files: [
              {
                type: "file",
                name:"Okta",
                details: [
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 1,
                  },
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 2,
                  },
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 3,
                  },
                ],
              },
            ],
          },
          {
            type: "folder",
            name: "LMS", 
            files: [
              {
                type: "file",
                name:"Okta",
                details: [
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 1,
                  },
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 2,
                  },
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 3,
                  },
                ],
              },
            ],
          },
          {
            type: "folder",
            name: "Share Point", 
            files: [
              {
                type: "file",
                name:"Okta",
                details: [
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 1,
                  },
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 2,
                  },
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 3,
                  },
                ],
              },
            ],
          },
          
        ],
      },
      {
        type: "folder",
        name: "Level 2 PLC",
        files: [
          {
            type: "folder",
            name: "Okta", 
            files: [
              {
                type: "file",
                name:"Okta",
                details: [
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 1,
                  },
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 2,
                  },
                  {
                    connectedApp: "SAP",
                    details: "This is a connected App",
                    attachment: "file.pdf",
                    id: 3,
                  },
                ],
              },
            ],
          },
          
        ],
      },
    ],
  },
];

export default function App() {
  let [data, setData] = useState(structure);

  const handleClick = (node) => {
    console.log(node);
  };
  const handleUpdate = (state) => {
    localStorage.setItem(
      "tree",
      JSON.stringify(state, function (key, value) {
        if (key === "parentNode" || key === "id") {
          return null;
        }
        return value;
      })
    );
  };

  useLayoutEffect(() => {
    try {
      let savedStructure = JSON.parse(localStorage.getItem("tree"));
      if (savedStructure) {
        setData(savedStructure);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className="App">
      <h2> Demo App </h2>
      <Tree data={data} onUpdate={handleUpdate} onNodeClick={handleClick} />
    </div>
  );
}
